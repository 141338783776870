<template lang="html">
    <div class="settingContent">
		<div class="handleBox flex flex-jc-sb flex-ai-c">
	        <h3 style="">{{$route.name}}</h3>
			<div class="handlebox flex flex-ai-c" style="margin-left:auto;margin-right:10px">
				<el-select v-model="search.status" size="small" placeholder="审核选项" clearable style="width:120px;margin-right:5px;" @change="getList">
					<el-option v-for="(item,index) in auditStatusList" :label="item.name" :value="item.id" :key=""></el-option>
				</el-select>
				<!-- <el-select v-model="search.serviceItem" size="small" placeholder="服务费选项" style="width:120px;margin-right:5px;" @change="getList">
					<el-option v-for="(item,index) in serviceItemList" :label="item.name" :value="item.id" :key=""></el-option>
				</el-select> -->
				<div class=" flex flex-ai-c">
					<el-input placeholder="名称/负责人/手机号" size="small" style="width:200px;" v-model="search.searchValue" @input="getList">
						<i slot="suffix" class="el-input__icon el-icon-search"></i>
					</el-input>
				</div>
			</div>
			<!-- <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="handleEdit()">新建</el-button> -->
	    </div>
        <div class="contentBox" style="width:100%;padding:0 40px">
            <el-table :data="list" size="medium" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" :max-height="tableHeight">
                <el-table-column prop="name" label="商户名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="admin.realname" label="负责人" align="center"></el-table-column>
                <el-table-column prop="admin.mobile" label="手机号"  align="center"></el-table-column>
				<el-table-column prop="ext_deadline_date" label="服务到期" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="ext_deadline_left_days" label="剩余天数" align="center">
					<template slot-scope="scope">
                        <p :style="{color:parseInt(scope.row.ext_deadline_left_days)<30?'#b30004':''}">{{scope.row.ext_deadline_left_days}}</p>
                    </template>
				</el-table-column>
				<el-table-column prop="login_count" label="登录次数" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="ext_login_last_time" label="最后一次登录时间" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="auditStatus" label="审核状态" align="center">
                    <template slot-scope="scope">
                        <!-- <p v-if="scope.row.status==2">待审核</p> -->
                        <p v-if="scope.row.status==1">已通过</p>
                        <p v-else>已拒绝</p>
                    </template>
                </el-table-column>
                <el-table-column label="审核操作" align="center">
                    <template slot-scope="scope">
                        <el-switch
                            style="display: block; height:24px;"
                            v-model="scope.row.status"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#008685"
                            @change="setOpen(scope.row.id,scope.row.status,scope.row.name)">
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width='220' align="center">
                    <template slot-scope="scope">
                        <!-- 跳收费统计 -->
                        <router-link :to="{name:'商户管理-服务费记录',query:{name:scope.row.name,id:scope.row.id}}">
                            <el-button type="text" @click="">服务费记录</el-button>
                        </router-link>
                        <el-button type="text" @click="addDay(scope.row)"  style="margin-left:10px">续期</el-button>
                        <el-button type="text" @click="edit(scope.row)"  style="margin-left:10px">编辑</el-button>
                        <!-- 跳 商户管理员-统计概况 -->
                        <!-- <el-button size="text" @click="goSub(scope.row.id,scope.row.name)">详情</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>

            <!-- 编辑弹框 -->
            <win :title="title" v-if="winIsShow" @winClose="winIsShow=false" @winEnter="submit">
                <el-form ref="edit" :rules="rules" :model="formData" label-width="80px">
                    <el-form-item label="商户名称" prop="name">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                </el-form>
            </win>
        </div>
    </div>
</template>

<script>
import win from "../../components/win.vue";
export default {
    components: {
        win
    },
    data: function() {
        return {
            search: {
                searchValue: '', //搜索值
                status: '', //审核状态
                serviceItem: '', //服务费选项
            },
            title: '编辑商户',
            winIsShow: false,
            formData: {

            },
            rules: {
                name: [{
                    required: true,
                    message: '请填写姓名',
                    trigger: 'change'
                }],
            },
            auditStatusList: [{
				name:'审核通过',
				id:1
			},{
				name:'审核拒绝',
				id:0
			}], //审核状态列表
            serviceItemList: [], //服务费选项列表
            value: {
                name: ''
            },
            tableHeight: 660,
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.init();
		// 禁用浏览器返回键
	　　history.pushState(null, null, document.URL);
	　　window.addEventListener('popstate', this.disableBrowserBack);
    },
    methods: {
        init: function() {
            // this.autoHeight();
			if (this.$route.params.form == 'login') {
	            this.$router.go(0)
	        }
            this.getList();
            // this.getAuditStatusList();
            // this.getServiceItemList();
        },
		disableBrowserBack() {
	　　　　history.pushState(null, null, document.URL);
	　　},
        //自动高度
        autoHeight() {
            var duoyu = 50 + 80  + 40;
            this.tableHeight = document.documentElement.clientHeight - duoyu;
            window.onresize = () => {
                this.tableHeight = document.documentElement.clientHeight - duoyu;
            }
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        // 续期
        addDay: function(detail) {
            this.$prompt('续期天数', '设置', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[1-9]\d*$/,
                inputErrorMessage: '格式不正确'
            }).then(({
                value
            }) => {
                var apiUrl = "/admin.company/useTimeAdd";
                this.http.post(apiUrl, {
                    id: detail.id,
                    num: value,
                }).then(res => {
                    this.getList();
                });
            }).catch(() => {

            });
        },
        //设置审核开关
        setOpen: function(id, open, name) {
            var apiUrl = "/admin.company/statusChange";
            this.http.post(apiUrl, {
                id,
                status: open,
            }).then(res => {
                if (open == 1) {
                    this.$message({
                        message: name + "审核已通过！",
                        type: "success"
                    });
                } else {
                    this.$message({
                        message: name + "审核已拒绝！",
                        type: "success"
                    });
                }
            });
        },
        // 审核状态列表
        getAuditStatusList: function() {
            var apiUrl = "/pcApi/Dictionary/getMerchantAuditStatus";
            this.http.post(apiUrl).then(res => {
                this.auditStatusList.push({
                    name: '审核选项',
                    id: '',
                });
                for (var i in res.list) {
                    this.auditStatusList.push({
                        name: res.list[i],
                        id: i,
                    })
                }
            });
        },
        // 服务费选项列表
        getServiceItemList: function() {
            var apiUrl = "/pcApi/Dictionary/getMerchantServiceItem";
            this.http.post(apiUrl).then(res => {
                this.serviceItemList.push({
                    name: '服务费选项',
                    id: '',
                });
                for (var i in res.list) {
                    this.serviceItemList.push({
                        name: res.list[i],
                        id: i,
                    })
                }
            });
        },
        // 获取列表
        getList: function() {
            var url = '/admin.company/index';
            this.http.post(url, {
                curr: this.curr,
                row: this.row,
                search: this.search.searchValue,
                status: this.search.status,
                // serviceItem: this.search.serviceItem,
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
            });
        },
        //修改
        edit: function(detail = false) {
            this.formData = JSON.parse(JSON.stringify(detail));
            this.winIsShow = true;
        },
        // 提交
        submit() {
            // 验证
            this.$refs['edit'].validate((valid) => {
                if (valid) {
                    var apiUrl = "/admin.company/nameChange";
                    this.http.post(apiUrl, this.formData).then(res => {
                        this.winIsShow = false;
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getList();
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 点详情时
        goSub: function(id, name) {
            var url = '/admin.company/rootLogin';
            this.http.post(url, {
                company_id: id
            }).then(res => {
                localStorage.setItem('merchantName', name);
                this.$router.push('/overview');
            });
        },
    },
	destroyed() {
	　　// 清除popstate事件 否则会影响到其他页面
	　　window.removeEventListener("popstate", this.disableBrowserBack, false);
	},
}
</script>

<style lang="css" scoped>
</style>
